import React, { Component } from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';

import { BASE_ZINDEX } from '../popup-factory';

class ValidateConfirmationPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

    this.handleClickYes = this.handleClickYes.bind(this);
  }

  handleClickYes() {
    const { redirect, method, params_arr, onClosePopup } = this.props;

    if(redirect) {
      this.setState({ loading: true }, function() {
        this.props.method(...this.props.params_arr);
      });
    }else{
      method(...params_arr);
      onClosePopup();
    }
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
        </div>
      </div>
    );
  }

  render() {
    const { method, params_arr, onClosePopup, index, message = 'You Sure?', description, redirect } = this.props;

    return (
      <div id="replace-header-modal" className="reveal tiny" style={{display: 'block', height: 'auto', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">{message}</h3>
          </div>
        </div>
        <div className="row column popup-content">
          {description ? <p>{description}</p> : null}
          {this.state.loading ?
            this.renderLoading()
          :
            <div className="expanded button-group">
              <a className="button" onClick={e => {e.preventDefault(); onClosePopup(); }}>No</a>
              <a className="alt button" onClick={e => {e.preventDefault(); this.handleClickYes(); }}>Yes</a>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
  };
};

const ConnectedValidateConfirmationPopup = connect(mapStateToProps, mapDispatchToProps)(ValidateConfirmationPopup);
export default ConnectedValidateConfirmationPopup;

